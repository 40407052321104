import React, { useState, useEffect } from 'react'
import firebase, { User } from 'firebase/app'
import { useCookies } from 'react-cookie'
import { useRouter } from 'next/router'
import 'firebase/auth'
import FirebaseClient from './FirebaseClient'

type AuthUser = {
  id: string
  email: string
  token: string
}

export const useAuthUser = (): {
  authUser: AuthUser | null
  logout: () => void
} => {
  const [cookies, setCookies, removeCookies] = useCookies(['auth'])
  const router = useRouter()

  const logout = async () => {
    return FirebaseClient.auth()
      .signOut()
      .then(() => {
        removeCookies('auth')
        window.heap.resetIdentity()
        router.push('/')
      })
      .catch(e => console.error(e))
  }

  return { authUser: cookies.auth, logout }
}

export const CurrentUserContext = React.createContext<User | null>(null)
